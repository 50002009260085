<template>
  <div class="page-header-index-wide">
    <div style="margin-bottom: 20px;display: flex;align-items: center;">

<!--      <div style="white-space: nowrap;font-weight: bold;">所在城市：</div>-->
<!--      <a-input-group compact>-->
<!--        <a-select show-search option-filter-prop="name" :filter-option="manageCityFilterOption" placeholder="所在城市"-->
<!--          v-model="cityId" style="width:200px" allowClear>-->
<!--          <a-select-option v-for="(item, index) in manageCityOptions" v-key="index" :value="item.id">{{item.name}}</a-select-option>-->
<!--        </a-select>-->
<!--        <a-button style="width: 60px;margin: 0;" @click="toSearch" icon="search"></a-button>-->
<!--      </a-input-group>-->
    </div>
    <a-row :gutter="12">
<!--      <a-col :xs="24" :sm="12" :md="8" :xl="4" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="采购单" :total="count.house_1_0">
          <div>
            今天新增：{{count.house_1_99}}
          </div>
          <template slot="footer">本月新增：<span>{{count.house_1_100}}</span></template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="8" :xl="4" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="销售单" :total="count.realter_2_0">
          <div>
            今天新增：{{count.realter_2_99}}
          </div>
          <template slot="footer">本月新增：<span>{{count.realter_2_100}}</span></template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="8" :xl="4" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="发货单" :total="count.company_3_0">
          <div>
            今天新增：{{count.company_3_99}}
          </div>
          <template slot="footer">本月新增：<span>{{count.company_3_100}}</span></template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="8" :xl="4" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="客户" :total="count.customer_4_0">
          <div>
            今天新增：{{count.customer_4_99}}
          </div>
          <template slot="footer">本月新增：<span>{{count.customer_4_100}}</span></template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="8" :xl="4" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="预订单" :total="count.order_5_0">
          <div>
            今天新增：{{count.order_5_99}}
          </div>
          <template slot="footer">本月新增：<span>{{count.order_5_100}}</span></template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="8" :xl="4" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="总交易额" :total="count.money_6_0">
          <div>
            今天新增：{{count.money_6_99}}
          </div>
          <template slot="footer">本月新增：<span>{{count.money_6_100}}</span></template>
        </chart-card>
      </a-col>-->
      <a-col :xs="24" :sm="12" :md="8" :xl="6" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="采购订单" :total="count.house_9_0">
          <div>
            今天新增：{{count.house_9_99}}
          </div>
          <template slot="footer">本月新增：<span>{{count.house_9_100}}</span></template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="8" :xl="6" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="打款金额(/万元)" :total="count.realter_10_0 | formatMoney ">
          <div>
            今天新增：{{count.realter_10_99 | formatMoney }}
          </div>
          <template slot="footer">本月新增：<span>{{count.realter_10_100 | formatMoney }}</span></template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="8" :xl="6" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="发货单金额(/万元)" :total="count.company_11_0 | formatMoney ">
          <div>
            今天新增：{{count.company_11_99 | formatMoney }}
          </div>
          <template slot="footer">本月新增：<span>{{count.company_11_100 | formatMoney }}</span></template>
        </chart-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="8" :xl="6" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="总交易额(/万元)" :total="count.money_12_0 | formatMoney ">
          <div>
            今天新增：{{count.money_12_99 | formatMoney }}
          </div>
          <template slot="footer">本月新增：<span>{{count.money_12_100 | formatMoney }}</span></template>
        </chart-card>
      </a-col>
    </a-row>

    <a-row :gutter="10">
      <a-col :md="24">
        <a-card :loading="loading" :bordered="false" :body-style="{padding: '0'}">
          <a-tabs default-active-key="9" size="large" @change="changeTab" :tab-bar-style="{marginBottom: '24px', paddingLeft: '16px'}">
            <div class="extra-wrapper" slot="tabBarExtraContent">
              <a-range-picker v-model="chartTime" :allowClear="false" :style="{width: '200px'}" @change="changeTimeRange"
                :ranges="ranges" />
            </div>
<!--            <a-tab-pane loading="true" tab="采购单" key="1">
              <highcharts :key="key" :options="chart1"></highcharts>
            </a-tab-pane>
            <a-tab-pane loading="true" tab="销售单" key="2">
              <highcharts :key="key" :options="chart2"></highcharts>
            </a-tab-pane>
            <a-tab-pane loading="true" tab="发货单" key="3">
              <highcharts :key="key" :options="chart3"></highcharts>
            </a-tab-pane>
            <a-tab-pane loading="true" tab="客户" key="4">
              <highcharts :key="key" :options="chart4"></highcharts>
            </a-tab-pane>
            <a-tab-pane loading="true" tab="交易额" key="5">
              <highcharts :key="key" :options="chart5"></highcharts>
            </a-tab-pane>-->
            <a-tab-pane loading="true" tab="采购订单" key="9">
              <highcharts :key="key" :options="chart9"></highcharts>
            </a-tab-pane>
            <a-tab-pane loading="true" tab="打款金额(/万元)" key="10">
              <highcharts :key="key" :options="chart10"></highcharts>
            </a-tab-pane>
            <a-tab-pane loading="true" tab="发货单金额(/万元)" key="11">
              <highcharts :key="key" :options="chart11"></highcharts>
            </a-tab-pane>
            <a-tab-pane loading="true" tab="总交易额(/万元)" key="12">
              <highcharts :key="key" :options="chart12"></highcharts>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import moment from 'moment'
  import {
    ChartCard,
    RankList
  } from '@/components'
  const qs = require('qs')
  export default {
    name: 'Analysis',
    components: {
      ChartCard,
      RankList
    },
    data() {
      return {
        cityId: "",
        manageCityOptions: [],
        loading: true,
        ranges: {
          '近一周': [moment().subtract(1, 'weeks'), moment()],
          '近一月': [moment().subtract(1, 'months'), moment()],
          '近一年': [moment().subtract(1, 'years'), moment()],
          '本周': [moment().startOf('week'), moment()],
          '本月': [moment().startOf('month'), moment()],
          '本年': [moment().startOf('year'), moment()]
        },
        ranges2: {
          '今天': [moment(), moment()],
          '近一周': [moment().subtract(1, 'weeks'), moment()],
          '近一月': [moment().subtract(1, 'months'), moment()],
          '近一年': [moment().subtract(1, 'years'), moment()],
          '本周': [moment().startOf('week'), moment()],
          '本月': [moment().startOf('month'), moment()],
          '本年': [moment().startOf('year'), moment()]
        },
        rankType: 0,
        rankTime: [moment().subtract(1, 'weeks'), moment()],
        rankList: [],
        count: {},
        chart9: {},
        chart10: {},
        chart11: {},
        chart12: {},
        // chart5: {},
        chartType: 9,
        chartDateType: 99,
        chartTime: [moment().subtract(1, 'weeks'), moment()],
        startTime: moment().subtract(1, 'weeks'),
        endTime: moment(),
        scale: "weeks",
        key: 1,
        // titles: ["采购单增长量趋势图", "销售单增长量趋势图", "发货单增长量趋势图", "客户增长量趋势图", "交易额增长量趋势图"],
        titles: ["采购订单增长量趋势图", "打款金额增长量趋势图", "发货单金额增长量趋势图", "总交易额增长量趋势图"],
        chartOptions: {
          // chart: {
          //   type: 'column'
          // },
          title: {
            text: '采购订单增长量趋势图'
          },
          xAxis: {
            categories: []
          },
          yAxis: {
            title: {
              text: '增长量'
            }
          },
          series: [{
            name: '增长量',
            data: []
          }],
          legend: {
            enabled: false
          },
          credits: {
            enabled: false
          }

        }
      }
    },
    methods: {
      // getPointByName() {
      //   const data = {
      //     address: '北京市海淀区上地十街10号',
      //     // ak: 'MaGIfq1f3oQtEy4W6YR0lG5wkAntGHdH' // 指方向提供的key，显示不可用，下面的key是许文的，暂时用一下
      //     ak: 'Sjg7NUzHU5dN5DF6F2l79zZY2W2PIDUL'
      //   }
      //   this.axios.post(`/api/base/system/map/getAddress?${qs.stringify(data)}`).then(res => {
      //     console.log(res)
      //   }).catch(err => console.log(err))
      // },
      getCardData(name, type, typeDate) {
        this.axios.post(`/api/base/system/report/getRealTimeReportData`, {
          realType: type,
          typeDate: typeDate,
          cityId:this.cityId,
        }).then(res => {
          this.count[`${name}_${type}_${typeDate}`] = res.body[0][1] || 0
        }).catch(err => {})
      },
      changeTab(e) {
        this.chartType = Number(e)
        this.key++
        this.getChartData()
      },
      changeTimeRange(dates) {
        this.key++
        this.getChartData()
      },
      getChartData() {
        let postData = Object.assign({}, {
          type: this.chartType,
          typeDate: this.chartTime[1].diff(this.chartTime[0], "months") > 1 ? 100 : 99,
          cityId:this.cityId,
          date1: this.chartTime[0].format("YYYY-MM-DD"),
          date2: this.chartTime[1].format("YYYY-MM-DD")
        })
        let chartOption = JSON.parse(JSON.stringify(this.chartOptions))
        this.axios.post(`/api/base/system/report/getReportByType`, postData).then(res => {
          chartOption.title.text = this.titles[this.chartType - 1]
          res.body.forEach(x => {
            chartOption.xAxis.categories.push(x[0])
            chartOption.series[0].data.push(x[1])
          })
          this[`chart${this.chartType}`] = chartOption
        }).catch(err => {})
      },
      getManageCities() {
        // this.axios.get(`/api/base/system/sysDistricts/listCity`).then(res => {
        //   this.manageCityOptions = res.body
        // }).catch(err => {})
      },
      manageCityFilterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      },
      toSearch(){
        this.getCardData("house", 9, 0)
        this.getCardData("house", 9, 99)
        this.getCardData("house", 9, 100)
        
        this.getCardData("realter", 10, 0)
        this.getCardData("realter", 10, 99)
        this.getCardData("realter", 10, 100)
        
        this.getCardData("company", 11, 0)
        this.getCardData("company", 11, 99)
        this.getCardData("company", 11, 100)
        
        /*this.getCardData("customer", 4, 0)
        this.getCardData("customer", 4, 99)
        this.getCardData("customer", 4, 100)
        
        this.getCardData("order", 5, 0)
        this.getCardData("order", 5, 99)
        this.getCardData("order", 5, 100)
        
        this.getCardData("money", 6, 0)
        this.getCardData("money", 6, 99)
        this.getCardData("money", 6, 100)*/


        this.getCardData("money", 12, 0)
        this.getCardData("money", 12, 99)
        this.getCardData("money", 12, 100)
        this.getChartData()

      }
    },
    created() {
    //   this.getManageCities()
      this.toSearch()
      setTimeout(() => {
        this.loading = !this.loading
      }, 1000)
    }
  }
</script>

<style lang="less" scoped>
  .extra-wrapper {
    line-height: 55px;
    padding-right: 24px;

    .extra-item {
      display: inline-block;
      margin-right: 24px;

      a {
        margin-left: 24px;
      }
    }
  }

  .antd-pro-pages-dashboard-analysis-twoColLayout {
    position: relative;
    display: flex;
    display: block;
    flex-flow: row wrap;

    &.desktop div[class^=ant-col]:last-child {
      position: absolute;
      right: 0;
      height: 100%;
    }
  }

  .antd-pro-pages-dashboard-analysis-salesCard {
    height: calc(100% - 24px);

    /deep/ .ant-card-head {
      position: relative;
    }
  }

  .dashboard-analysis-iconGroup {
    i {
      margin-left: 16px;
      color: rgba(0, 0, 0, .45);
      cursor: pointer;
      transition: color .32s;
      color: black;
    }
  }

  .analysis-salesTypeRadio {
    position: absolute;
    right: 54px;
    bottom: 12px;
  }
</style>
